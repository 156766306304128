import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

export const useIsMobileView = () => {
    const [isMobileView, setIsMobileView] = useState(isMobile && window.innerWidth < 993)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(isMobile && window.innerWidth < 1024)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobileView
}

import React, { Dispatch, MutableRefObject, SetStateAction } from 'react'

import { Property } from '../types/property'
import { CurrencyData, FiltersButton } from './PageProvider'

export enum Pages {
    HomePage = 'HomePage',
    GlobePage = 'GlobePage',
    CountryPage = 'CountryPage',
    CityPage = 'CityPage',
    CommunityPage = 'CommunityPage',
    ResidencyPage = 'ResidencyPage',
    HousePlanPage = 'HousePlanPage',
}

export type MapFiltersType = 'status' | 'unitType' | 'price' | 'plotArea'

interface PageContextProps {
    pageTransitionTiming: number
    resetButtons: () => void
    activeButton: string | null
    setActiveButton: (buttonName: string | null) => void

    subActiveButton: string | null
    setSubActiveButton: (buttonName: string | null) => void

    displayedProperty: Property | null
    setDisplayedProperty: React.Dispatch<React.SetStateAction<Property | null>>
    isUnitInformationsOpen: boolean
    toggleUnitInformations: () => void
    isVRMode: boolean
    setIsVRMode: React.Dispatch<React.SetStateAction<boolean>>
    toggleVRMode: () => void
    selectedRoom: string
    setSelectedRoom: (room: string) => void
    selectedFilter: string | null
    setSelectedFilter: (filter: string | null) => void
    mapFilters: {
        key: MapFiltersType
        value: string | number | string[] | number[]
    }[]
    setMapFilters: ({ key, value }: { key: string; value: string | number | string[] | number[] }) => void
    getMapFilter: (key: MapFiltersType) => string | number | string[] | number[] | undefined
    resetMapFilters: () => void
    areCaptionsOn: boolean
    toggleCaptions: (state: boolean) => void
    navigateToBreadcrumb?: (pageName: string) => void
    activeAmenitiesButtons: { [key: string]: boolean }
    setActiveAmenitiesButtons: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
    activeFilterButtons: FiltersButton[] | []
    setActiveFilterButtons: React.Dispatch<React.SetStateAction<FiltersButton[] | []>>
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    properties: Property[] | []
    setProperties: React.Dispatch<React.SetStateAction<Property[]>>
    displayedProperties: Property[]
    setDisplayedProperties: React.Dispatch<React.SetStateAction<Property[]>>
    mapZoom: number
    setMapZoom: React.Dispatch<React.SetStateAction<number>>
    mapCenter: { lat: number; lng: number }
    setMapCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>
    currencies: CurrencyData
    currentFloor: number
    setCurrentFloor: React.Dispatch<React.SetStateAction<number>>
    cameraUrl: string
    setCameraUrl: React.Dispatch<React.SetStateAction<string>>
    iframeVR: MutableRefObject<HTMLIFrameElement | null>
    viewSide: number
    setViewSide: React.Dispatch<React.SetStateAction<number>>
    displayedLocation: number | null
    setDisplayedLocation: React.Dispatch<React.SetStateAction<number | null>>
    clickedUnit: Property | null
    setClickedUnit: Dispatch<SetStateAction<Property | null>>
    disclaimerIsOpen: boolean
    setDisclaimerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultPageContextValue: PageContextProps = {
    pageTransitionTiming: 1000,
    resetButtons: () => {},
    activeButton: null,
    setActiveButton: () => {},

    subActiveButton: null,
    setSubActiveButton: () => {},

    displayedProperty: null,
    setDisplayedProperty: () => {},
    isUnitInformationsOpen: false,
    toggleUnitInformations: () => {},
    isVRMode: false,
    toggleVRMode: () => {},
    setIsVRMode: () => {},
    selectedRoom: 'Kitchen',
    setSelectedRoom: () => {},
    selectedFilter: null,
    setSelectedFilter: () => {},
    mapFilters: [
        { key: 'status', value: ['available', 'booked', 'sold'] },
        { key: 'unitType', value: [] },
        { key: 'price', value: [0, 6000] },
        { key: 'plotArea', value: [0, 100] },
    ],
    setMapFilters: () => {},
    getMapFilter: () => '',
    resetMapFilters: () => {},
    areCaptionsOn: true,
    toggleCaptions: () => {},
    activeAmenitiesButtons: {
        hospital: false,
        park: false,
        retail: false,
        pocketPark: false,
        mosque: false,
        school: false,
    },
    setActiveAmenitiesButtons: () => {},
    activeFilterButtons: [],
    setActiveFilterButtons: () => {},
    isModalOpen: false,
    setIsModalOpen: () => {},
    properties: [],
    setProperties: () => {},
    displayedProperties: [],
    setDisplayedProperties: () => {},
    mapZoom: 0.3,
    setMapZoom: () => {},
    mapCenter: { lat: 0, lng: 0 },
    setMapCenter: () => {},
    currencies: {},
    currentFloor: 32,
    setCurrentFloor: () => {},
    cameraUrl: 'Corona Camera01',
    setCameraUrl: () => {},
    iframeVR: { current: null },
    viewSide: 1,
    setViewSide: () => {},
    displayedLocation: null,
    setDisplayedLocation: () => {},
    clickedUnit: null,
    setClickedUnit: () => {},
    disclaimerIsOpen: false,
    setDisclaimerIsOpen: () => {},
}

export const PageContext = React.createContext(defaultPageContextValue)

import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useLocation } from 'react-router-dom'

import { PageContext } from '../../../contexts'
import { useIsMobileView } from '../../../hooks/useIsMobileView'
import { POB1LogoIcon } from '../../../icons/icons'
import BackButton from '../BackButton/BackButton'
import styles from './TopBar.module.css'

interface Props {
    isVRMode?: boolean
}

const TopBar: React.FC<Props> = ({ isVRMode }) => {
    const { pageTransitionTiming } = useContext(PageContext)
    const isMobileView = useIsMobileView()
    const location = useLocation()
    const [displayedContent, setDisplayedContent] = useState<string | null>(null)

    useEffect(() => {
        const topbarTimeout = setTimeout(() => {
            setDisplayedContent(location.pathname)
        }, pageTransitionTiming)

        return () => clearTimeout(topbarTimeout)
    }, [location.pathname, pageTransitionTiming])

    if (displayedContent === '/') {
        return null
    }

    return (
        <div className={styles.container}>
            {isMobileView && (
                <Link to='/' className={styles.logo}>
                    <POB1LogoIcon />
                </Link>
            )}
            {!isMobileView && <BackButton />}
        </div>
    )
}

export default TopBar

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { Amenity, AmenityResponse } from '../types/amenity'
import { AmenityType } from '../types/amenityType'

export const amenitiesApi = createApi({
    reducerPath: 'amenitiesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://',
    }),
    endpoints: builder => ({
        getAmenities: builder.query<Amenity[], void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/amenities?per_page=500',
            transformResponse: async (response: AmenityResponse[]) => {
                return response.map(amenity => {
                    return {
                        ...amenity.acf,
                        id: amenity.id,
                        name: amenity.title.rendered,
                        type: amenity['type-amenitie'][0],
                        imageId: amenity.acf.image,
                        line_icon: amenity.acf.svg_data,
                        gallery: amenity.acf.gallery,
                        popup_direction: amenity.acf.popup_direction,
                    }
                })
            },
        }),
        getAmenityById: builder.query<Amenity, { id: number }>({
            query: ({ id }) => `agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/amenities/${id}`,
            transformResponse: async (response: AmenityResponse) => {
                return {
                    ...response.acf,
                    id: response.id,
                    name: response.title.rendered,
                    type: response['type-amenitie'][0],
                    imageId: response.acf.image,
                    line_icon: response.acf.svg_data,
                }
            },
        }),
        getAmenitiesImages: builder.query<any[], void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/media?per_page=100',
            transformResponse: async (response: any[]) => {
                return response.map(image => {
                    return {
                        ...image,
                        id: image.id,
                        image: (image.media_details?.sizes?.medium?.source_url || image.media_details?.sizes?.large?.source_url) ?? null,
                    }
                })
            },
        }),
        getAmenityTypes: builder.query<AmenityType[], void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/type-amenitie?per_page=100',
            transformResponse: async (response: any[]) => {
                return response.map(amenityType => {
                    return {
                        ...amenityType,
                        typeColor: amenityType.acf.type_color,
                        typeIcon: amenityType.acf.type_icon,
                        pages: amenityType.acf.type,
                    }
                })
            },
        }),
    }),
})

export const { endpoints, useGetAmenitiesQuery, useGetAmenitiesImagesQuery, useGetAmenityTypesQuery, useLazyGetAmenityByIdQuery } = amenitiesApi

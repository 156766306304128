// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyrightText_container__LmSe0 {
    color: var(--primary-light-08);
    font-size: var(--font-size-6);
    position: absolute;
    bottom: 2dvh;
    height: auto;
    font-family: var(--font-medium), sans-serif;
    text-decoration: none;
    z-index: 1000;
    text-shadow: 1px 1px 2px black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 2px;
}

@media screen and (max-width: 768px) {
    .CopyrightText_container__LmSe0 {
        font-size: var(--font-size-4);
        text-align: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/CopyrightText/CopyrightText.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,2CAA2C;IAC3C,qBAAqB;IACrB,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,eAAe;AACnB;;AAEA;IACI;QACI,6BAA6B;QAC7B,kBAAkB;IACtB;AACJ","sourcesContent":[".container {\n    color: var(--primary-light-08);\n    font-size: var(--font-size-6);\n    position: absolute;\n    bottom: 2dvh;\n    height: auto;\n    font-family: var(--font-medium), sans-serif;\n    text-decoration: none;\n    z-index: 1000;\n    text-shadow: 1px 1px 2px black;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    column-gap: 2px;\n}\n\n@media screen and (max-width: 768px) {\n    .container {\n        font-size: var(--font-size-4);\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CopyrightText_container__LmSe0`
};
export default ___CSS_LOADER_EXPORT___;
